
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addPurchaseRequistionRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const uom_data = ref<Array<UOM>>([]);
    const router = useRouter();
    const type_data = ref([]);

    const product_data = ref([
      // {
      //   product_id: 2,
      //   product_name: "AMNS PPGI Coil IS-2062 E250A",
      //   product_short_name: "AM/NS PPGI Coil IS-2062 E250A",
      // },
      // {
      //   product_id: 1,
      //   product_name: "JSW PPGI Coil IS-2062 E250 BR",
      //   product_short_name: "JSW PPGI Coil IS-2062 E250 BR",
      // },
    ]);

    interface UOM {
      uom_id: number;
      uom_name: string;
    }
    getAddData();
    getUOM();
    getPurchaseRequitionType();
    getProductList();

    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      name: "",
      date: new Date(),
      type_select: null,
      enquiryNumber: "",
      alternativename: "",
      nob_select: "",
      group_type_select: "",
      industry_type_select: "",
      business_type_select: "",
      incorporationateDate: "",
      pan: "",
      cinn: "",
      llpinn: "",
      registrationNo: "",
      roc_select: "",
      website: "",
    });

    const myData = ref([
      {
        product: "",
        batchno: "",
        quantity: "",
        uom: "",
        rate: "",
        remark: "",
      },
    ]);

    const rules = ref({
      type_select: [
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
    });

    function deleteRow(row) {
      myData.value.splice(row, 1);
    }

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = myData.value.map((item) => {
        return {
          product_id: item.product,
          product_batch_no: item.batchno,
          product_qunatity: parseFloat(item.quantity),
          uom_id: item.uom,
          product_rate: parseFloat(item.rate),
          product_remark: "",
        };
      });
      let validateProductList = myData.value.filter((item) => {
        return (
          item.product != "" &&
          item.quantity != "" &&
          item.uom != "" &&
          item.rate != ""
        );
      });
      if (
        validateProductList === [] ||
        validateProductList.length != myData.value.length
      ) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Fill All required product fields.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        stock_product_type_id: data.type_select,

        is_approved: true,
        mm_status_id: 1,
        created_user_id: "1",
        created_ip: "0.0.0.0",
        created_uagent: "Test",
        active: true,
        product_details: product_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/mm_purchase_request/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPurchaseRequistionRef.value);
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      myData.value.push({
        product: "",
        batchno: "",
        quantity: "",
        uom: "",
        rate: "",
        remark: "",
      });
      //   console.log("table data.." + tableData.value);
    }

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPurchaseRequitionType() {
      await store
        .dispatch(ActionsFi.CUST_GET_REQUISITION_TYPES)
        .then(({ data }) => {
          debugger;
          type_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getProductList() {
      var values = {};
      if (formData.value["type_select"] === 1) {
        values = { search_term: "", company_id: 0 };
      } else {
        values = {
          // product_id: 0,
          // page: 1,
          // records_per_page: 100,
          search_term: "",
        };
      }
      // console.log(formData.value["type_select"]);
      // alert(formData.value["type_select"]);
      let action =
        formData.value["type_select"] === 1
          ? Actions.CUST_GET_PRODUCT_DROPDOWN_LIST
          : ActionsFi.CUST_GET_PRODUCT_NON_SALEABLE_LIST;

      await store
        .dispatch(action, values)
        .then((data) => {
          debugger;
          product_data.value = data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addPurchaseRequistionRef,
      addProduct,
      getPurchaseRequitionType,
      myData,
      deleteRow,
      getProductList,
      uom_data,
      type_data,
      product_data,
    };
  },
});
