
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPurchaseRequisitionModal from "@/components/modals/forms/AddPurchaseRequisitionModal.vue";
import AddProductPRModal from "@/components/modals/forms/AddProductPRModal.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import { useRoute, useRouter } from "vue-router";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import _ from "lodash";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddPurchaseRequisitionModal,
    AddProductPRModal,
    MixedWidget10,
    // Datatable,
  },
   methods: {
    onChange() {
      if (this.formData.startDate == "") {
        this.getCompanyList('')
      }
    },
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const router = useRouter();
    const listVisible = ref<boolean>(true);
    const type_data = ref<Array<productTypeData>>([]);

    const formData = ref({
      startDate: "",
      endDate: "",
      pr_no: "",
    });

    interface productTypeData {
      stock_product_type_id: number;
      created_user_id: number;
      created_time: string;
      created_ip: string;
      created_uagent: string;
      updated_user_id: number;
      updated_time: string;
      updated_ip: string;
      updated_uagent: string;
      active: string;
      stock_product_type: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }
    interface WIJournal {
      pr_id: string;
      pr_no: string;
      pr_date: string;
      based_on: string;
      pr_remarks: string;
      status_id: string;

      // purchase_requisition_no: string;
      // purchase_requisition_date: string;
      // purchase_requisition_based_on: string;
      // purchase_requisition_type: string;
      // purchase_requisition_grand_total: string;
      // purchase_requisition_remark: string;
      // purchase_requisition_id: string;

      // inward_warehouse_id: string;
      // outward_warehouse_id: string;
    }

    getPurchaseRequitionType();

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    
    // const startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    // const endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    // const startDate1 = ref();
    // startDate1.value = [startDate , endDate]
    // console.log( startDate1.value[0]) 
    // formData.value.startDate = startDate1.value
    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      //loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
      var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          user_id: user.user_id,
          start_date: start_data,
          end_date: end_date,
          pr_no: formData.value.pr_no,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_PURCHASE_REQUISITION_LIST, values)
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));
            tableData.value = [];
            const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.length; j++) {
              // if (data[j]["is_approved"]) {
              //   status_label = "Yes";
              //   status_color = "success";
              // } else {
              //   status_label = "No";
              //   status_color = "danger";
              // }

              // if (data[j]["active"]) {
              //   active_label = "Yes";
              //   active_color = "success";
              // } else {
              //   active_label = "No";
              //   active_color = "danger";
              // }

              resultsM.value = Array({
                pr_id: data[j]["pr_id"],
                pr_no: data[j]["pr_no"],
                pr_date: data[j]["pr_date"],
                based_on: data[j]["based_on"],
                pr_remarks: data[j]["pr_remarks"],
                status_id: data[j]["is_completed"],
                purchase_requisition_id: data[j]["pr_id"],
                maintained_by: data[j]["maintained_by"]
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const Pdfdata = async (id) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "user_id" : user.user_id,
        "doc_type" : 1,
        "doc_id" : id
      };
      console.log("DB DATA")
      console.log(db_data)
      //loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.CUST_PDF_REQUEST, db_data)
          .then(({ data }) => {
           console.log("DATATATATA")
           console.log(data.request_id)
           console.log('https://print.elogicals.com/print/pr/' +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id)
            //loading.value = false;
            window.open
            ( "https://print.elogicals.com/print/pr/" +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id )
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // const getCompanyList = async (data) => {
    //   loadingData.value = true;

    //   try {
    //     var values = {
    //       company_id: 0,
    //       search_term: data,
    //       page: parseInt(paginationData.value["activePage"]),
    //       records_per_page: parseInt(paginationData.value["perPage"]),
    //     };

    //     await store
    //       .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
    //       .then(({ data }) => {
    //         JwtService.saveUserData({
    //           rakesh_count: data.rakesh_count,
    //           nikhil_count: data.nikhil_count,
    //           mansi_count: data.mansi_count,
    //         });
    //         console.log(JwtService.getUserData());

    //         tableData.value = [];

    //         paginationData.value["total"] = data.total_records;
    //         paginationData.value["start"] = data.records_from;
    //         paginationData.value["end"] = data.records_upto;
    //         paginationData.value["activePage"] = data.page;
    //         paginationData.value["totPage"] = data.total_pages;
    //         paginationData.value["pageRange"] = [];
    //         for (
    //           let index = data.bar_range_start;
    //           index < data.bar_range_end;
    //           index++
    //         ) {
    //           paginationData.value["pageRange"].push(index);
    //         }

    //         var resultsM = ref<Array<WICompanies>>([]);
    //         var status_label = "";
    //         var status_color = "";
    //         var active_label = "";
    //         var active_color = "";

    //         for (let j = 0; j < data.result_list.length; j++) {
    //           if (data.result_list[j]["company_verified_yes_no"]) {
    //             status_label = "Verified";
    //             status_color = "success";
    //           } else {
    //             status_label = "Unverified";
    //             status_color = "danger";
    //           }

    //           if (data.result_list[j]["active"]) {
    //             active_label = "Yes";
    //             active_color = "success";
    //           } else {
    //             active_label = "No";
    //             active_color = "danger";
    //           }

    //           resultsM.value = Array({
    //             company_id: data.result_list[j]["company_id"],
    //             user: {
    //               group: data.result_list[j]["company_group_type_name"],
    //               avatar:
    //                 "https://cdn.elogicals.com/customers/" +
    //                 data.result_list[j]["company_id"] +
    //                 "/logo/" +
    //                 data.result_list[j]["company_id"] +
    //                 ".png",
    //               name: data.result_list[j]["company_name"],
    //             },
    //             comp_type: data.result_list[j]["company_business_type_name"],
    //             status: {
    //               label: status_label,
    //               color: status_color,
    //             },
    //             active: {
    //               label: active_label,
    //               color: active_color,
    //             },
    //             roc: data.result_list[j]["company_roc_name"],
    //             nob: data.result_list[j]["company_nob_name"],
    //             doi: data.result_list[j]["company_doi"],
    //             pan: data.result_list[j]["company_pan"],
    //           });

    //           tableData.value.splice(
    //             j,
    //             resultsM.value.length,
    //             ...resultsM.value
    //           );
    //         }

    //         loadingData.value = false;
    //       })
    //       .catch(({ response }) => {
    //         tableData.value = [];
    //         loadingData.value = false;
    //       });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Purchase Requisition", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].company_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_pr")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    async function getPurchaseRequitionType() {
      await store
        .dispatch(ActionsFi.CUST_GET_REQUISITION_TYPES)
        .then(({ data }) => {
          debugger;
          type_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      formData,
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      type_data,
      getPurchaseRequitionType,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      router,
      Pdfdata
    };
  },
});
